import React from 'react'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import { Provider } from 'react-redux'
import AnimatedCursor from 'react-animated-cursor'

import Routes from './router/Routes'
import ScrollToTop from './components/ScrollToTop'

// store
import configureStore from './store'

const App = () => {
  return (
    <Provider store={configureStore()}>
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color='114, 182, 38'
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      <ScrollToTop />
      <NotificationContainer />
      <Routes />
    </Provider>
  )
}

export default App
