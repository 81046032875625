import { combineReducers } from 'redux'

// Global Reducers
import modeReducer from './modeReducer'

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer () {
  const rootReducer = combineReducers({
    mode: modeReducer
  })

  return rootReducer
}
