import React, { Suspense } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import NotFound from '../views/NotFound'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollTopBehaviour from '../components/ScrollTopBehaviour'

const Routes = () => {
  return (
    <>
      <Suspense
        fallback={
          <div>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1
              }}
              open
            >
              <CircularProgress color='inherit' size='5rem' />
            </Backdrop>
          </div>
        }
      >
        <Router>
          <ScrollTopBehaviour />
          <Switch>
            <Route
              exact
              path='/'
              component={React.lazy(() => import('../views/Home'))}
            />
            <Route
              path='/owns/world'
              exact
              component={React.lazy(() => import('../views/World'))}
            />
            <Route
              path='/owns/:id'
              component={React.lazy(() => import('../views/Detail'))}
            />
            <Route
              path='/owns'
              exact
              component={React.lazy(() => import('../views/Ownership'))}
            />
            <Route
              path='/home'
              component={React.lazy(() => import('../views/Home'))}
            />
            <Route path='/404' exact component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
    </>
  )
}

export default Routes
