import { DARK } from '../constant/modeConstants'

const initstate = {
  scheme: false
}

// Mutations/Reducer
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initstate, action) => {
  switch (action.type) {
    case DARK:
      return Object.assign({}, state, {
        scheme: action.value
      })
    default:
      return state
  }
}

// gettters
export const getDarkMode = state => state.mode.scheme
